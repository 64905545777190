import Button from '@core-ui/button';
import { useQuery } from '@core-ui/redux-router';
import { updateQuery } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { X } from '@phosphor-icons/react';
import { FormattedMessage, IntlMessageKeys, useIntl } from 'components/Intl';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  clearCreateEditRequestFiles,
  resetCreateEditRequestPanelFormValuesToInitial,
} from 'pages/backoffice/Requests/CreateEditRequestPanel/actions';
import { getCreateEditRequestUiDisabled } from 'pages/backoffice/Requests/CreateEditRequestPanel/selector';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import React from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';

const CreateEditRequestPanelFormHeader = () => {
  const disabled = useAppSelector(getCreateEditRequestUiDisabled);

  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles();
  const query = useQuery<IRequestsQueryParams>();
  const intl = useIntl();

  const handleClosePanel = () => {
    if (query.panelMode === 'create') {
      history.replace(
        updateQuery<IRequestsQueryParams>({
          selectedRequestId: undefined,
          panelMode: undefined,
        })
      );
    } else if (query.panelMode === 'edit') {
      batch(() => {
        dispatch(resetCreateEditRequestPanelFormValuesToInitial());
        dispatch(clearCreateEditRequestFiles());
      });

      history.replace(
        updateQuery<IRequestsQueryParams>({
          panelMode: 'view',
        })
      );
    }
  };

  const headerLabel: IntlMessageKeys = query.panelMode === 'create' ? 'label.new' : 'label.edit';

  return (
    <Grid item container sx={styles.container}>
      <Typography variant="h4">
        <FormattedMessage id={headerLabel} />
      </Typography>

      <Button
        variant="outlined"
        onClick={handleClosePanel}
        icon={<X />}
        disabled={disabled}
        aria-label={intl.formatMessage({ id: 'aria.label.close_panel' })}
      />
    </Grid>
  );
};

export default CreateEditRequestPanelFormHeader;
