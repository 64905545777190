import {
  getViewPanelDisabled,
  getViewPanelLoading,
  getViewPanelStateValue,
} from 'pages/backoffice/Requests/ViewRequestPanel/selector';
import { createSelector } from 'reselect';
import { StatusV2Enum } from '@/generated';

export default createSelector(
  [getViewPanelLoading, getViewPanelDisabled, getViewPanelStateValue],
  (loading, disabled, request) => ({
    dropzoneDisabled: loading || disabled || request?.status === StatusV2Enum.DECLINED,
  })
);
