import { getCurrentBoatId } from '@/app/selectors';
import State from '@/app/types/state';
import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { getAmountFormatter, getCurrencyCodeById } from '@/dictionary/selector';
import { getSingleWalletFilters } from '@/pages/backoffice/Wallets/SingleWalletFilters/selector';
import { createSelector } from 'reselect';

const getChunkLoading = (state: State) => state.backoffice.wallets.singleWalletList.chunkLoading;
const getAllDataReceived = (state: State) => state.backoffice.wallets.singleWalletList.isAllDataReceived;

export const chunkLoaderSelector = createSelector(
  [getChunkLoading, getAllDataReceived],
  (isChunkLoading, isAllDataReceived) => ({
    isChunkLoading,
    isAllDataReceived,
  })
);

export const getSingleWalletListState = (state: State) => state.backoffice.wallets.singleWalletList;

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getSingleWalletListState], (state) => ({
  loading: state.loading,
  loaded: state.loaded,
  hasData: state.hasData,
  error: state.error,
}));

const getOrderBy = (state: State) => state.backoffice.wallets.singleWalletList.orderBy;
const getOrder = (state: State) => state.backoffice.wallets.singleWalletList.order;

export const getSingleWalletSortOrder = createSelector([getOrderBy, getOrder], (orderBy, order) => ({
  orderBy,
  order,
}));

export const getPaginationPage = (state: State) => state.backoffice.wallets.singleWalletList.paginationPage;

const getPlaceholders = (state: State) => state.backoffice.wallets.singleWalletDragNDrop.placeholders;

const getFiltersApplied = createSelector([getSingleWalletFilters], (filters) =>
  Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return Boolean(value);
  })
);

export default createSelector(
  [
    getPlaceholders,
    getSingleWalletListState,
    getAmountFormatter,
    getCurrencyCodeById,
    getCurrentBoatId,
    getFiltersApplied,
  ],
  (placeholders, singleWalletList, amountFormatter, currencyCodeGetter, boatId, isFiltersApplied) => ({
    placeholders,
    items: singleWalletList.value,
    currencyCodeGetter,
    amountFormatter,
    boatId,
    isFiltersApplied,
  })
);
