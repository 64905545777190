import React from 'react';
import { useDispatch } from 'react-redux';
import { IMenuOption } from 'components/Menu/Menu';
import { FormattedMessage } from 'components/Intl';
import { NotePencil, X } from '@phosphor-icons/react';
import { COLOURS } from '@core-ui/styles';
import MoreButton from 'components/MoreButton';
import { updateQuery } from '@core-ui/url';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import { useHistory } from 'react-router-dom';
import { Nullable } from '@core-ui/types';
import { openBackofficeModal } from 'pages/backoffice/Modals/actions';
import { DeclineRequestModalData } from 'pages/backoffice/Modals/command';
import { StatusV2Enum } from '@/generated';

interface IProps {
  requestId: Nullable<number>;
  requestStatus?: StatusV2Enum;
  disabled: boolean;
}

const ViewRequestPanelHeaderMoreButton = (props: IProps) => {
  const { requestId, requestStatus, disabled } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleEdit = () => {
    if (requestId) {
      history.replace(
        updateQuery<IRequestsQueryParams>({
          selectedRequestId: requestId,
          panelMode: 'edit',
        })
      );
    }
  };

  const handleDecline = () => {
    if (requestId) {
      dispatch(openBackofficeModal(new DeclineRequestModalData(requestId)));
    }
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.edit" />,
      icon: <NotePencil />,
      onClick: handleEdit,
      visible: true,
      dataTestId: 'requests-view-request-panel-header-more-button-edit-option',
    },
    {
      title: <FormattedMessage id="label.decline_all_documents_and_request" />,
      textColor: COLOURS.Strawberry.Solid[600],
      icon: <X color={COLOURS.Strawberry.Solid[600]} />,
      onClick: handleDecline,
      visible: requestStatus !== StatusV2Enum.DECLINED,
      dataTestId: 'requests-view-request-panel-header-more-button-decline-option',
    },
  ];

  return (
    <MoreButton
      options={options}
      disabled={disabled}
      buttonVariant="text"
      dataTestId="requests-view-request-panel-header-more-button"
    />
  );
};

export default ViewRequestPanelHeaderMoreButton;
