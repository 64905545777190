import { ApproveStatusEnum, StatusV2Enum } from '@/generated';
import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { CheckCircle, Prohibit } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import isNil from 'lodash-es/isNil';
import { openBackofficeModal } from 'pages/backoffice/Modals/actions';
import { ApproveDocumentModalData, DeclineDocumentModalData } from 'pages/backoffice/Modals/command';
import { PARENT_PAGE } from 'pages/backoffice/Modals/consts';
import React from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

interface IProps {
  requestId?: number;
  requestStatus?: StatusV2Enum;
  documentId: number;
  documentApproveStatus?: ApproveStatusEnum;
  disabled?: boolean;
  documentPaid?: boolean;
}

const DocumentApproveButtons = (props: IProps) => {
  const { requestId, requestStatus, documentId, documentApproveStatus, disabled, documentPaid } = props;

  const requestDeclined = requestStatus === StatusV2Enum.DECLINED;
  const documentApproved = documentApproveStatus === ApproveStatusEnum.APPROVED;
  const documentDeclined = documentApproveStatus === ApproveStatusEnum.DECLINED;

  const approveButtonDisabled = disabled || requestDeclined || documentApproved;
  const declineButtonDisabled = disabled || requestDeclined || documentDeclined;

  const styles = useStyles({
    requestDeclined,
    documentApproved,
    documentDeclined,
  });
  const dispatch = useDispatch();

  const handleApproveDocument = () => {
    if (!isNil(documentId) && !isNil(requestId)) {
      dispatch(openBackofficeModal(new ApproveDocumentModalData(documentId, PARENT_PAGE.REQUESTS, requestId)));
    }
  };

  const handleDeclineDocument = () => {
    if (!isNil(documentId) && !isNil(requestId)) {
      dispatch(
        openBackofficeModal(new DeclineDocumentModalData(documentId, PARENT_PAGE.REQUESTS, requestId, documentPaid))
      );
    }
  };

  return (
    <Grid item container gap={2} width="auto">
      <Button
        variant="outlined"
        disabled={approveButtonDisabled}
        onClick={handleApproveDocument}
        sx={styles.approveButton}
        label={<FormattedMessage id="label.approve" />}
        startIcon={
          <SvgIcon htmlColor={requestDeclined ? COLOURS.Coconut.Solid[500] : COLOURS.Avocado.Solid[600]}>
            <CheckCircle />
          </SvgIcon>
        }
      />

      <Button
        variant="outlined"
        disabled={declineButtonDisabled}
        onClick={handleDeclineDocument}
        sx={styles.declineButton}
        label={<FormattedMessage id="label.decline" />}
        startIcon={
          <SvgIcon htmlColor={COLOURS.Strawberry.Solid[600]}>
            <Prohibit />
          </SvgIcon>
        }
      />
    </Grid>
  );
};

export default DocumentApproveButtons;
