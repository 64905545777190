import { DocTypeEnum, type DocumentBaseV2Schema } from '@/generated';
import * as Sentry from '@sentry/browser';
import State from 'app/types/state';
import { exhaustiveGuard } from 'app/utils/app';
import { getAmountFormatter, getCurrencyCodeById } from 'dictionary/selector';
import {
  getViewPanelDisabled,
  getViewPanelLoading,
  getViewPanelStateValue,
} from 'pages/backoffice/Requests/ViewRequestPanel/selector';
import { createSelector } from 'reselect';

const getDocuments = (state: State) => state.backoffice.requests.viewRequestPanel.value?.documents;

// document - оцифрованный документ
const getFilteredRequestDocuments = createSelector([getDocuments], (documents) => {
  const filteredDocuments =
    // TODO: эта фильтрация сейчас по сути не нужна т.к. нет квотного флоу
    //  при этом но квоты мы всё ещё отображаем т.к. они не выпилены из мобилки и могут попасть в систему. Когда будем делать квотный флоу, то переделаем эту функцию фильтрации
    documents?.reduce<DocumentBaseV2Schema[]>((acc, item) => {
      switch (item.document_type) {
        case DocTypeEnum.POST_PAYMENT_INVOICE:
        case DocTypeEnum.PRE_PAYMENT_INVOICE:
        case DocTypeEnum.RECEIPT:
          acc.push(item);
          break;
        case DocTypeEnum.QUOTE:
          acc.push(item);
          break;
        case DocTypeEnum.DEFAULT:
          break;
        case undefined:
          break;
        default:
          return exhaustiveGuard(item.document_type);
      }

      return acc;
    }, []) ?? [];

  // TODO: temp логика фильтрации до момента решения вопроса с квотным флоу
  const documentsMap = new Map<string, DocumentBaseV2Schema[]>();

  filteredDocuments.forEach((document) => {
    const key = `${document.vendor_name}-${document.total_price}-${document.total_price_currency_id}-${document.vat}-${document.vat_percent}-${document.vat_currency_id}`;

    if (!documentsMap.has(key)) {
      documentsMap.set(key, [document]);
    } else {
      documentsMap.set(key, [...documentsMap.get(key)!, document]);
    }
  });

  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const noDuplicatesDocuments = Array.from(documentsMap.values()).map((documents) => {
    if (documents.length > 2) {
      /*
       * Если этот кейс произошёл, то это что-то на бэке и нужно пинговать бэкендера
       * Два документа с одним ключом могут быть: один инвойс, второй квота. Но больше двух быть не должно
       */
      Sentry.captureMessage(
        `More than 2 documents with the same key: ${documents[0].vendor_name}-${documents[0].total_price}-${documents[0].total_price_currency_id}-${documents[0].vat}-${documents[0].vat_percent}-${documents[0].vat_currency_id}`
      );

      return documents[0];
    }

    if (documents.length === 1) {
      return documents[0];
    }

    const invoice = documents.find(
      (document) =>
        document.document_type === DocTypeEnum.POST_PAYMENT_INVOICE ||
        document.document_type === DocTypeEnum.PRE_PAYMENT_INVOICE
    );

    if (invoice) {
      return invoice;
    }

    return documents[0];
  });

  return noDuplicatesDocuments;
});

const getFiles = (state: State) => state.backoffice.requests.viewRequestPanel.value?.files_tree;

// file - не оцифрованный документ
export const getFilteredFiles = createSelector([getFiles], (files) => {
  return (
    files?.filter(
      (file) =>
        // TODO: фильтрация ниже временно выключена т.к. некоторые клиенты всё ещё используют квоты
        // TODO: сейчас файлы могут иметь только типы prepayment и postpayment, но это может измениться когда будем добавлять квотный флоу
        // (file.doc_type === INVOICE_FILE_UPLOAD_TYPE_ENUM.POSTPAYMENT ||
        //   file.doc_type === INVOICE_FILE_UPLOAD_TYPE_ENUM.PREPAYMENT) &&
        !file.is_digitized
    ) ?? []
  );
});

export default createSelector(
  [
    getViewPanelLoading,
    getViewPanelDisabled,
    getViewPanelStateValue,
    getFilteredRequestDocuments,
    getFilteredFiles,
    getCurrencyCodeById,
    getAmountFormatter,
  ],
  (loading, disabled, request, documents, files, getCurrencyCode, amountFormatter) => ({
    buttonsDisabled: loading || disabled,
    request,
    documents,
    files,
    getCurrencyCode,
    amountFormatter,
  })
);
