import State from 'app/types/state';
import { createSelector } from 'reselect';

export const getCreateEditRequestState = (state: State) => state.backoffice.requests.createEditRequestPanel;
export const getCreateEditRequestValue = (state: State) => state.backoffice.requests.createEditRequestPanel.value;
export const getCreateEditRequestInitialValue = (state: State) =>
  state.backoffice.requests.createEditRequestPanel.initialValue;
export const getCreateEditRequestLoading = (state: State) => state.backoffice.requests.createEditRequestPanel.loading;
export const getCreateEditRequestDisabled = (state: State) => state.backoffice.requests.createEditRequestPanel.disabled;

export const getCreateEditRequestUiDisabled = createSelector(
  [getCreateEditRequestLoading, getCreateEditRequestDisabled],
  (loading, disabled) => loading || disabled
);

export const getCreateEditFiles = (state: State) => state.backoffice.requests.createEditRequestPanel.files;
export const getCreateEditFilenames = (state: State) => state.backoffice.requests.createEditRequestPanel.filenames;

export default createSelector([getCreateEditRequestValue], (value) => ({
  storedRequestId: value?.id,
}));
