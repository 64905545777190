import {
  SIDE_REQUEST_PANEL_ANIMATION_TIMEOUT,
  SIDE_REQUEST_PANEL_DEFAULT_HEIGHT,
  SIDE_REQUEST_PANEL_DEFAULT_WIDTH,
} from 'pages/backoffice/Requests/consts';
import { Resizable } from 're-resizable';
import React, { ReactNode } from 'react';
import { Transition } from 'react-transition-group';
import useStyles from './styles';

interface IProps {
  panelClosed: boolean;
  children: ReactNode;
  dataTestId?: string;
}

const SingleRequestSidePanel = (props: IProps) => {
  const { panelClosed, children, dataTestId } = props;
  const styles = useStyles();

  return (
    <Transition in={panelClosed} timeout={SIDE_REQUEST_PANEL_ANIMATION_TIMEOUT}>
      {(state: 'entering' | 'entered' | 'exiting' | 'exited') => (
        <Resizable
          enable={{ left: true, right: false }}
          minWidth={SIDE_REQUEST_PANEL_DEFAULT_WIDTH}
          maxWidth={SIDE_REQUEST_PANEL_DEFAULT_WIDTH}
          defaultSize={{
            width: SIDE_REQUEST_PANEL_DEFAULT_WIDTH,
            height: SIDE_REQUEST_PANEL_DEFAULT_HEIGHT,
          }}
          minHeight={SIDE_REQUEST_PANEL_DEFAULT_HEIGHT}
          handleStyles={{ left: styles.disabledHandleStyles }}
          style={{
            ...styles.resizableStyles,
            ...styles.transitionStyles[state],
          }}
        >
          {children}
        </Resizable>
      )}
    </Transition>
  );
};

export default SingleRequestSidePanel;
