import { Nullable } from '@core-ui/types';
import { showNotification } from 'app/actions';
import { FormApi } from 'final-form';
import { createRequest, updateRequest } from 'pages/backoffice/Requests/CreateEditRequestPanel/actions';
import { ICreateEditRequestForm } from 'pages/backoffice/Requests/types';
import { Dispatch } from 'redux';

const handleSubmitForm =
  (_: Nullable<ICreateEditRequestForm>, dispatch: Dispatch, files: File[], requestId?: number) =>
  (formValues: ICreateEditRequestForm, form: FormApi<ICreateEditRequestForm>) => {
    if (requestId) {
      const fieldMap = form.getState().modified;

      const trimmedTitle = formValues.title?.trim();
      const trimmedDescription = formValues.description?.trim();

      const parsedFormValues: ICreateEditRequestForm = {
        ...formValues,
        title: fieldMap?.title ? trimmedTitle : undefined,
        description: fieldMap?.description ? trimmedDescription : undefined,
      };

      dispatch(updateRequest({ formValues: parsedFormValues, files }));
    } else {
      if (files.length === 0) {
        dispatch(
          showNotification({
            variant: 'error',
            title: 'notification.error.text.request_must_have_document',
          })
        );

        return;
      }

      const parsedFormValues: ICreateEditRequestForm = {
        title: formValues.title?.trim(),
        description: formValues.description?.trim(),
      };

      dispatch(
        createRequest({
          formValues: parsedFormValues,
          files,
        })
      );
    }
  };

export default handleSubmitForm;
