import { FileStatusEnum, RequestDetailOutputV2Schema, UpdatePaidDocument } from '@/generated';
import { GET, POST, PUT } from 'api/oceanApi';
import { responseError } from 'app/sagas';
import { getCurrentBoatId } from 'app/selectors';
import { showNotification } from 'app/actions';
import { setCreateEditRequestFromViewPanel } from 'pages/backoffice/Requests/CreateEditRequestPanel/actions';
import { updateItemInRequestList } from 'pages/backoffice/Requests/RequestsList/actions';
import { IFilesPostResponse, INVOICE_FILE_UPLOAD_TYPE_ENUM } from 'pages/backoffice/Requests/types';
import { getRequestId, getViewPanelStateValue } from 'pages/backoffice/Requests/ViewRequestPanel/selector';
import { Action } from 'redux-actions';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { addFilesToRequest, setSingleRequest, setSingleRequestDisabled } from './actions';

function* getSingleRequest({ payload }: Action<actions.IGetSingleRequest>) {
  try {
    const { id } = payload;
    const boatId: number = yield select(getCurrentBoatId);

    const request: RequestDetailOutputV2Schema = yield call(GET, `/v2/requests/${id}`, {
      boat_id: boatId,
    });

    yield all([
      put(
        actions.setSingleRequest({
          value: request,
          hasData: true,
        })
      ),
      put(setCreateEditRequestFromViewPanel({ request })),
    ]);
  } catch (e) {
    yield all([
      put(
        actions.setSingleRequest({
          hasData: false,
          error: e as Error,
        })
      ),
      call(responseError, e),
    ]);
  } finally {
    yield put(actions.setSingleRequestDisabled(false));
  }
}

function* setSingleRequestFromCreateEditPanelModule({ payload }: Action<actions.ISetSingleRequestFromCreateEditPanel>) {
  const { request } = payload;

  yield put(
    actions.setSingleRequest({
      value: request,
      hasData: true,
    })
  );
}

function* updatePaidStatus({ payload }: Action<actions.IUpdatePaidStatus>) {
  try {
    const { requestId, documentId, status } = payload;

    yield put(setSingleRequestDisabled(true));

    const body: UpdatePaidDocument = {
      request_id: requestId,
      document_id: documentId,
      is_paid: status,
    };

    yield call(PUT, '/invoices/paid', body);

    yield all([
      put(actions.getSingleRequest({ id: requestId })),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.document_update_paid_status',
        })
      ),
    ]);

    yield take(setSingleRequest);
    const updatedRequest: RequestDetailOutputV2Schema = yield select(getViewPanelStateValue);

    yield put(updateItemInRequestList({ item: updatedRequest }));
  } catch (e) {
    yield all([call(responseError, e)]);
  } finally {
    yield put(setSingleRequestDisabled(false));
  }
}

interface IUploadFilesToRequest {
  requestId: number;
  files: File[];
}

const createFormData = (payload: IUploadFilesToRequest) => {
  const formData = new FormData();

  // TODO: upload all new files as prepayment invoices until we add a Quote flow to the Requests
  formData.append('doctype', INVOICE_FILE_UPLOAD_TYPE_ENUM.PREPAYMENT);
  formData.append('request_id', String(payload.requestId));

  payload.files.forEach((file) => formData.append('fileobjects', file));

  return formData;
};

function* uploadFiles({ payload }: Action<actions.IViewRequestPanelUploadFiles>) {
  try {
    const { files } = payload;

    yield put(setSingleRequestDisabled(true));

    const requestId: number = yield select(getRequestId);
    const formData = createFormData({ requestId, files });

    const response: IFilesPostResponse = yield call(POST, '/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const updatedFilenames: string[] = files.map((file) => file.name);

    const newFormattedFiles = response.files.map((file, index) => ({
      id: file.file_id,
      filename: updatedFilenames[index],
      status: FileStatusEnum.BEING_DIGITIZED,
      is_digitized: false,
      doc_type: INVOICE_FILE_UPLOAD_TYPE_ENUM.PREPAYMENT,
      content_type: '',
      child_files: [],
    }));

    yield all([
      put(addFilesToRequest({ newFiles: newFormattedFiles })),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.files_uploaded',
        })
      ),
    ]);

    const updatedRequest: RequestDetailOutputV2Schema = yield select(getViewPanelStateValue);
    yield put(setCreateEditRequestFromViewPanel({ request: updatedRequest }));
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setSingleRequestDisabled(false));
  }
}

export default [
  takeLatest(actions.getSingleRequest, getSingleRequest),
  takeLatest(actions.setSingleRequestFromCreateEditPanelModule, setSingleRequestFromCreateEditPanelModule),
  takeLatest(actions.updatePaidStatus, updatePaidStatus),
  takeLatest(actions.uploadFiles, uploadFiles),
];
