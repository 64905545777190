import { useQuery } from '@core-ui/redux-router';
import { useAppSelector } from 'hooks/useAppSelector';
import { SingleRequestSidePanel } from 'pages/backoffice/Requests/components';
import { EMPTY_REQUEST } from 'pages/backoffice/Requests/consts';
import {
  getCreateEditRequest,
  resetCreateEditRequestPanelState,
  setCreateEditRequest,
} from 'pages/backoffice/Requests/CreateEditRequestPanel/actions';
import { SingleRequestPanelCreateEditHeader } from 'pages/backoffice/Requests/CreateEditRequestPanel/components';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import CreateEditRequestPanelForm from './components/CreateEditRequestPanelForm';
import selector from './selector';

const CreateEditRequestPanel = () => {
  const { storedRequestId } = useAppSelector(selector);
  const query = useQuery<IRequestsQueryParams>();
  const dispatch = useDispatch();

  const panelClosed = query.panelMode !== 'edit' && query.panelMode !== 'create';

  useEffect(() => {
    batch(() => {
      if (query.panelMode === 'create') {
        dispatch(resetCreateEditRequestPanelState());
        dispatch(setCreateEditRequest({ value: EMPTY_REQUEST, hasData: true }));
      } else if (query.panelMode === 'edit' && storedRequestId !== query.selectedRequestId) {
        dispatch(resetCreateEditRequestPanelState());
        dispatch(getCreateEditRequest({ id: query.selectedRequestId }));
      }
    });
  }, [panelClosed, query.selectedRequestId]);

  return (
    <SingleRequestSidePanel panelClosed={panelClosed} dataTestId="requests-create-edit-request-panel">
      <SingleRequestPanelCreateEditHeader />
      <CreateEditRequestPanelForm />
    </SingleRequestSidePanel>
  );
};

export default CreateEditRequestPanel;
