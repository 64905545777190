import Button from '@core-ui/button';
import { useQuery } from '@core-ui/redux-router';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { FormattedMessage, IntlMessageKeys } from 'components/Intl';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCreateEditRequestUiDisabled } from 'pages/backoffice/Requests/CreateEditRequestPanel/selector';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import React from 'react';
import useStyles from './styles';

const Footer = () => {
  const disabled = useAppSelector(getCreateEditRequestUiDisabled);

  const styles = useStyles();
  const query = useQuery<IRequestsQueryParams>();

  const buttonLabel: IntlMessageKeys = query.panelMode === 'create' ? 'label.add_request' : 'label.save';

  return (
    <Grid item container sx={styles.footerContainer}>
      <Divider sx={styles.divider} />

      <Grid item container sx={styles.buttonContainer}>
        <Button
          type="submit"
          variant="contained"
          color="blue"
          label={<FormattedMessage id={buttonLabel} />}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default Footer;
