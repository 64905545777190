import {
  getViewPanelDisabled,
  getViewPanelLoading,
  getViewPanelStateValue,
} from 'pages/backoffice/Requests/ViewRequestPanel/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getViewPanelLoading, getViewPanelDisabled, getViewPanelStateValue],
  (loading, disabled, value) => ({
    loading,
    disabled,
    requestName: value?.name,
    requestId: value?.id ?? null,
    requestStatus: value?.status,
  })
);
