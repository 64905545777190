import { createSelector } from 'reselect';
import {
  getCreateEditRequestUiDisabled,
  getCreateEditRequestValue,
} from 'pages/backoffice/Requests/CreateEditRequestPanel/selector';
import { StatusV2Enum } from '@/generated';

export default createSelector([getCreateEditRequestValue, getCreateEditRequestUiDisabled], (request, uiDisabled) => ({
  dropzoneDisabled: uiDisabled || request?.status === StatusV2Enum.DECLINED,
}));
