import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { buttonBaseClasses, buttonClasses } from '@mui/material';

export default (props: {
  requestDeclined?: boolean;
  documentApproved?: boolean;
  documentDeclined?: boolean;
}): Record<string, SxProps<Theme>> => {
  const { requestDeclined, documentDeclined, documentApproved } = props;

  const approveButtonDisabled = requestDeclined || documentApproved;
  const approveButtonGreyedOut = approveButtonDisabled && !documentApproved;

  return {
    approveButton: {
      [`&.${buttonBaseClasses.root}.${buttonClasses.outlined}`]: {
        minWidth: 'auto',
        borderRadius: '16px',
        cursor: `${approveButtonDisabled ? 'default' : 'pointer'}`,
        color: `${approveButtonGreyedOut ? COLOURS.Coconut.Solid[500] : COLOURS.Avocado.Solid[600]}`,

        background: `${
          approveButtonGreyedOut
            ? COLOURS.Coconut.Solid[200]
            : documentApproved
            ? COLOURS.Avocado.Solid[100]
            : COLOURS.WHITE
        }`,

        borderColor: `${
          approveButtonGreyedOut
            ? COLOURS.Coconut.Solid[200]
            : documentApproved
            ? COLOURS.Avocado.Solid[600]
            : COLOURS.Coconut.Solid[200]
        }`,

        '&:hover': {
          borderColor: `${
            approveButtonGreyedOut
              ? COLOURS.Coconut.Solid[200]
              : documentApproved
              ? COLOURS.Avocado.Solid[700]
              : COLOURS.Avocado.Solid[600]
          }`,
        },
      },
    },

    declineButton: {
      [`&.${buttonBaseClasses.root}.${buttonClasses.outlined}`]: {
        minWidth: 'auto',
        borderRadius: '16px',
        cursor: `${requestDeclined ? 'default' : 'pointer'}`,
        color: `${COLOURS.Strawberry.Solid[600]}`,

        background: `${requestDeclined || documentDeclined ? COLOURS.Strawberry.Solid[100] : COLOURS.WHITE}`,

        borderColor: `${
          requestDeclined || documentDeclined ? COLOURS.Strawberry.Solid[600] : COLOURS.Coconut.Solid[200]
        }`,

        '&:hover': {
          borderColor: `${
            requestDeclined || documentDeclined ? COLOURS.Strawberry.Solid[600] : COLOURS.Strawberry.Solid[700]
          }`,
        },
      },
    },
  };
};
