import { ROUTES } from '@/app/consts/routes';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { IBackofficeState } from '@/pages/backoffice/reducer';
import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { resetCreateEditRequestPanelState } from 'pages/backoffice/Requests/CreateEditRequestPanel/actions';
import {
  resetRequestFilters,
  setRequestsFiltersFromQuery,
  updateRequestsFiltersQuery,
} from 'pages/backoffice/Requests/RequestsFilters/actions';
import { getRequestList, resetRequestsList } from 'pages/backoffice/Requests/RequestsList/actions';
import RequestsRoot from 'pages/backoffice/Requests/RequestsRoot';
import { resetViewRequestPanelState } from 'pages/backoffice/Requests/ViewRequestPanel/actions';

const key: keyof IBackofficeState = 'requests';

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.REQUESTS})`,
    component: RequestsRoot,
    effects: [
      { events: ['on_enter'], trigger: resetRequestsList },
      { events: ['on_enter'], trigger: resetRequestFilters },
      { events: ['on_enter'], trigger: resetCreateEditRequestPanelState },
      { events: ['on_enter'], trigger: resetViewRequestPanelState },
      { events: ['on_enter'], trigger: setRequestsFiltersFromQuery },
      { events: ['on_enter'], trigger: updateRequestsFiltersQuery },
      { events: ['on_enter'], trigger: getRequestList },
    ],
  },
];

export const REQUEST_PATHS = extractFlattenPaths(routes);

export default routes;
