import Grid from '@mui/material/Grid';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  ViewRequestPanelContentDocumentsItemFile,
  ViewRequestPanelContentDocumentsItemDocument,
} from 'pages/backoffice/Requests/ViewRequestPanel/components/ViewRequestPanelContentDocuments/components';
import selector from './selector';

const ViewRequestPanelContentDocumentsList = () => {
  const { documents, request, files, buttonsDisabled, getCurrencyCode, amountFormatter } = useAppSelector(selector);

  return (
    <Grid
      item
      container
      direction="column"
      gap={2}
      wrap="nowrap"
    >
      {documents?.map((document) => (
        <ViewRequestPanelContentDocumentsItemDocument
          key={document.id}
          request={request}
          document={document}
          buttonsDisabled={buttonsDisabled}
          amountFormatter={amountFormatter}
          getCurrencyCode={getCurrencyCode}
        />
      ))}

      {files?.map((file) => (
        <ViewRequestPanelContentDocumentsItemFile key={file.id} file={file} buttonsDisabled={buttonsDisabled} />
      ))}
    </Grid>
  );
};

export default ViewRequestPanelContentDocumentsList;
