import Immutable from '@core-ui/immutable';
import reducer, { List } from '@core-ui/reducers_handlers';
import { Nullable, SORT_ORDER_ENUM } from '@core-ui/types';
import { myocean__routes__wallets__enums__OrderFields, WalletContentSchema, WalletRecordSchema } from '@/generated';
import { Action, handleActions } from 'redux-actions';
import * as actions from './actions';

export interface ISingleWalletListState extends List<WalletRecordSchema>, Omit<WalletContentSchema, 'records'> {
  orderBy: Nullable<myocean__routes__wallets__enums__OrderFields>;
  order: SORT_ORDER_ENUM;
  paginationPage: number;
}

const defaultState = Immutable<ISingleWalletListState>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
  orderBy: myocean__routes__wallets__enums__OrderFields.CREATED_AT,
  order: SORT_ORDER_ENUM.DESC,
  paginationPage: 0,
  total: 0,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setOrderBy.toString()]: (state, { payload }: Action<string>) => {
      return state.set('orderBy', payload);
    },
    [actions.setOrder.toString()]: (state, { payload }: Action<SORT_ORDER_ENUM>) => {
      return state.set('order', payload);
    },
    [actions.setPaginationPage.toString()]: (state, { payload }: Action<number>) => {
      return state.set('paginationPage', payload);
    },
    [actions.setTotal.toString()]: (state, { payload }: Action<number>) => {
      return state.set('total', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
