import { FileReasonOfReturn, FileStatusEnum, StatusV2Enum } from '@/generated';
import { Nullable } from '@core-ui/types';

// TODO: актуализировать все типы в этом файле и удалить лишние

export type ISingleRequestPanelMode = 'create' | 'edit' | 'view';

export interface IRequestsQueryParams {
  selectedRequestId?: number;
  panelMode?: ISingleRequestPanelMode;
}

export interface ICreateEditRequestForm {
  id?: number;
  title?: string;
  description?: string;
  status?: StatusV2Enum;
}

// TODO: этих типов ответа ручки нет в схемах
export interface IFilesPostItem {
  file_id: number;
}

export interface IFilesPostResponse {
  files: IFilesPostItem[];
  success: boolean;
}

// TODO: что это такое? оно ещё нужно? - уже не нужно, это легаси версия CalculatedRequestStatusEnum
export enum CALCULATED_STATUS {
  REQUEST_AWAITING_APPROVAL = 'Request awaiting approval',
  REQUEST_APPROVED = 'Request approved',
  COLLECTING_QUOTES = 'Collecting quotes',
  REQUEST_DECLINED = 'Request declined',
  QUOTE_BEING_DIGITIZED = 'Quote(s) being digitized',
  QUOTE_AWAITING_APPROVAL = 'Quote awaiting approval',
  QUOTE_APPROVED = 'Quote approved',
  QUOTE_DECLINED = 'Quote declined',
  AWAITING_INVOICE = 'Awaiting invoice',
  INVOICE_BEING_DIGITIZED = 'Invoice(s) being digitized',
  INVOICE_AWAITING_APPROVAL = 'Invoice awaiting approval',
  INVOICE_DECLINED = 'Invoice declined',
  AWAITING_PAYMENT = 'Awaiting payment',
  PAID = 'Paid',
  DONE = 'Done',
  PAYMENT_PENDING_APPROVAL = 'Payment pending approval',
  PAID_DELIVERED = 'Paid & delivered',
  REQUEST_DISPUTE = 'Request in dispute',
  QUOTE_ERROR = 'Quote has error',
  INVOICE_ERROR = 'Invoice has error',
  NONE = 'Unknown',
  DELIVERED = 'Delivered',
}

export enum CREW_DOCUMENT_STATUS {
  DECLINED = 'declined',
  APPROVED = 'approved',
  AWAITING = 'awaiting',
}

export enum INVOICE_DOCUMENT_STATUS {
  DECLINED = 'declined',
  APPROVED = 'approved',
  AWAITING = 'awaiting',
}

export enum QUOTE_STATUS_ENUM {
  DECLINED = 'declined',
  APPROVED = 'approved',
  AWAITING = 'awaiting',
}

export enum QUOTE_LINE_STATUS {
  APPROVED = 'approved',
  DECLINED = 'declined',
  AWAITING = 'awaiting',
}

export enum PAYMENT_REQUEST_STATUS_ENUM {
  DRAFT = 'draft',
  SUBSTANCE = 'substance',
  QUOTE = 'quote',
  INVOICE = 'invoice',
  PAYMENT = 'payment',
  DECLINED = 'declined',
}

export enum PAYMENT_REQUEST_TYPE_ENUM {
  DEFAULT = 'default',
  EMERGENCY = 'emergency',
  CASH = 'cash',
  SALARY = 'salary',
}

export enum PAYMENT_REQUEST_APPROVE_STATUS {
  AWAITING = 'awaiting',
  APPROVED = 'approved',
  DECLINED = 'declined',
}

const DEFAULT_REQUEST_STATUSES = [
  CALCULATED_STATUS.QUOTE_AWAITING_APPROVAL,
  CALCULATED_STATUS.INVOICE_BEING_DIGITIZED,
  CALCULATED_STATUS.INVOICE_AWAITING_APPROVAL,
  CALCULATED_STATUS.PAID,
  CALCULATED_STATUS.DONE,
];

export const DEFAULT_REQUEST_STATUS_OPTIONS = DEFAULT_REQUEST_STATUSES.map((option) => ({
  label: option,
  value: option,
}));

export const REQUEST_STATUS_OPTIONS = Object.values(CALCULATED_STATUS).map((option) => ({
  label: option,
  value: option,
}));

export interface IPaymentRequestFile {
  id: number;
  filename: string;
  content_type: string;
  doc_type: FILE_UPLOAD_TYPE | INVOICE_FILE_UPLOAD_TYPE_ENUM;
  img_height?: number;
  img_width?: number;
  document_id: Nullable<number>;
  link: string;
  is_preview?: boolean;
  reason_of_return?: FileReasonOfReturn;
  status: FileStatusEnum;
  is_digitized: boolean;
  child_files?: IPaymentRequestFile[];
}

export interface IComment {
  id: number;
  employee_id: number;
  comment: string;
}

export interface IUserApprove {
  id: number;
  employee_id: number;
  status?: 'approved' | 'declined';
  updated_at: string;
  created_at: string;
}

export enum INVOICE_CALCULATED_STATUS_ENUM {
  AWAITING = 'awaiting',
  APPROVED = 'approved',
  DECLINED = 'declined',
  PAID = 'paid',
}

export interface IQuoteLine {
  id: number;
  title: string;
  qty: number;
  qty_type: 'GR' | 'KG' | 'PKG' | 'Punnet' | 'L' | 'Gal';
  unit_price: number;
  unit_price_currency_id: number;
  agency_fee?: number;
  agency_fee_currency_id?: number;
  total_price: number;
  subtotal_price?: number;
  total_price_currency_id: number;
  vat: number;
  vat_percent: number;
  vat_type: string;
  vat_currency_id?: number;
  discount: number;
  discount_currency_id?: number;
  disbursement?: number;
  disbursement_currency_id?: number;
  discount_type: string;
  line_status: QUOTE_LINE_STATUS;
  updated_at: string;
  created_at: string;
  cost_center: string;
  cost_article: string;
  cost_article_details: number;
  cost_group: number;
  color: string;
}

export interface IInvoice {
  id: number;
  vendor: string;
  agency_fee: number;
  agency_fee_currency_id: number;
  created_at: number;
  crew_document_status: CREW_DOCUMENT_STATUS;
  discount: number;
  delivery_due: number;
  issue_date: number;
  is_paid: boolean;
  lead_days: number;
  is_delivered: boolean;
  discount_type: 'percent' | 'numeric';
  discount_type_currency_id?: number;
  document_approve_status: INVOICE_DOCUMENT_STATUS;
  subtotal: number;
  total_price: number;
  total_price_currency_id: number;
  vat: number;
  vat_type: 'percent' | 'numeric';
  vat_currency_id: number;
  shipping_cost: number;
  shipping_cost_currency_id: number;
  invoice_approves: IApprove[];
  lines: IInvoiceLine[];
  document_type: REQUEST_DOCUMENTS_TYPE_ENUM;
  calculated_status?: INVOICE_CALCULATED_STATUS_ENUM;
  files: IPaymentRequestFile[];
}

export interface IInvoiceLine {
  id: number;
  updated_at: string;
  created_at: string;
  title: string;
  qty: number;
  qty_type: 'GR' | 'KG' | 'PKG' | 'Punnet' | 'L' | 'Gal';
  discount: number;
  discount_currency_id?: number;
  discount_type: 'percent' | 'numeric';
  disbursement?: number;
  disbursement_currency_id?: number;
  unit_price: number;
  unit_price_currency_id: number;
  agency_fee?: number;
  agency_fee_currency_id?: number;
  total_price: number;
  subtotal_price: number;
  total_price_currency_id: number;
  cost_center: string;
  cost_group: number;
  cost_article: string;
  cost_article_details: number;
  vat: number;
  vat_type: 'percent' | 'numeric';
  vat_currency_id?: number;
  vat_percent: number;
  line_status: QUOTE_LINE_STATUS;
}

export enum APPROVE_STATUS_ENUM {
  APPROVED = 'approved',
  AWAITING = 'awaiting',
  DECLINED = 'declined',
}

export interface IApprove {
  employee_id: number;
  status: APPROVE_STATUS_ENUM;
}

export enum VAT_TYPE_ENUM {
  PERCENT = 'percent',
  NUMERIC = 'numeric',
}

export interface IQuote {
  id: number;
  title: string;
  vendor: string;
  delivery_due: number;
  due_date: number;
  issue_date: string | number;
  total_price: number;
  subtotal: number;
  total_price_currency_id: number;
  shipping_cost: number;
  shipping_cost_currency_id: number;
  package_cost: number;
  package_cost_currency_id: number;
  agency_fee: number;
  agency_fee_currency_id: number;
  vat: number;
  vat_type: VAT_TYPE_ENUM;
  vat_percent: number;
  vat_currency_id: number;
  document_approve_status: QUOTE_STATUS_ENUM;
  crew_document_status: CREW_DOCUMENT_STATUS;
  quote_approves: IApprove[];
  lines: IQuoteLine[];
  invoice_approves: IApprove[];
  files: IPaymentRequestFile[];
  document_type: REQUEST_DOCUMENTS_TYPE_ENUM;
}

export enum CASH_PAYMENT_TYPE_ENUM {
  CASH = 'cash',
  CREDIT_CARD = 'creadit_card',
}

export interface IPaymentRequest {
  id: number;
  name: string;
  chat_id: string;
  amount: number;
  amount_currency_id: number;
  boat_id: number;
  department_id: number;
  created_at: number;
  updated_at: number;
  description: string;
  employee_id: number;
  employee_first_name: string;
  employee_last_name: string;
  is_delivered: boolean;
  is_paid: boolean;
  is_dispute: boolean;
  is_archive: boolean;
  is_error: boolean;
  files_number: number;
  documents_number: number;

  request_type: PAYMENT_REQUEST_TYPE_ENUM;
  request_status: PAYMENT_REQUEST_STATUS_ENUM;
  approve_status: PAYMENT_REQUEST_APPROVE_STATUS;
  calculated_status: CALCULATED_STATUS;
  documents?: Array<IQuote | IInvoice>;
  cash_payment_type?: CASH_PAYMENT_TYPE_ENUM;

  files_tree?: IPaymentRequestFile[];
  files?: IPaymentRequestFile[];
  default_files?: IPaymentRequestFile[];
  comments?: IComment[];
  request_approves: IApprove[];
  expired_at: Nullable<number>;
  features: IOpenLayerFeature[];
}

export enum FILE_UPLOAD_TYPE {
  DEFAULT = 'default',
  QUOTE = 'quote',
  RECEIPT = 'receipt',
  SALARY = 'salary',
}

export enum INVOICE_FILE_UPLOAD_TYPE_ENUM {
  PREPAYMENT = 'pre_payment_invoice',
  POSTPAYMENT = 'post_payment_invoice',
}

export const INVOICE_FILE_UPLOAD_TYPE = Object.values(INVOICE_FILE_UPLOAD_TYPE_ENUM);

export enum REQUEST_DOCUMENTS_TYPE_ENUM {
  QUOTE = 'quote',
  RECEIPT = 'receipt',
  PREPAYMENT = 'pre_payment_invoice',
  POSTPAYMENT = 'post_payment_invoice',
}

export interface IOpenLayerFeature {
  id?: number;
  coordinates: [number, number];
  request_id: number;
}
