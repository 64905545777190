import { RequestMinimalOutputV2Schema } from '@/generated';
import { useQuery } from '@core-ui/redux-router';
import { TableCell, TableRow } from '@core-ui/table';
import { updateQuery } from '@core-ui/url';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from 'app/consts/app';
import { AmountFormatter, CurrencyCodeGetterById } from 'app/types/common';
import { formatTime } from 'app/utils/dates';
import ListTooltip from 'components/ListTooltip';
import { RequestStatusBadge } from 'pages/backoffice/Requests/components';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';

interface IProps {
  item: RequestMinimalOutputV2Schema;
  costArticleMappingEnabled: boolean;
  amountFormatter: AmountFormatter;
  currencyCodeGetter: CurrencyCodeGetterById;
}

const RequestsListRow = (props: IProps) => {
  const { item, costArticleMappingEnabled, amountFormatter, currencyCodeGetter } = props;

  const query = useQuery<IRequestsQueryParams>();
  const isItemSelected = query.selectedRequestId === item.id;

  const styles = useStyles(isItemSelected);
  const history = useHistory();

  const handleOpenRequest = () => {
    history.replace(
      updateQuery<IRequestsQueryParams>({
        selectedRequestId: item.id,
        panelMode: 'view',
      })
    );
  };

  const createdAt = item.created_at ? formatTime(item.created_at, { dateFormat: DATE_FORMAT }) : undefined;

  const createdBy = item.created_by_email
    ? item.created_by_email
    : [item.created_by_first_name, item.created_by_last_name].filter(Boolean).join(' ') || null;

  const vendorNames = item.vendors?.map((vendor) => vendor.name);
  const displayedCostArticles = costArticleMappingEnabled
    ? item.client_cost_articles?.map((clientCostArticle) => clientCostArticle.name)
    : item.cost_articles?.map((costArticle) => costArticle.name);

  return (
    <TableRow sx={styles.tableRow} onClick={handleOpenRequest}>
      <TableCell sx={styles.nameCell}>
        {item.name}
      </TableCell>

      <TableCell sx={styles.cell}>
        <RequestStatusBadge status={item.status} />
      </TableCell>

      <TableCell sx={styles.cell}>{createdAt}</TableCell>

      <TableCell sx={styles.cell}>{createdBy}</TableCell>

      <TableCell sx={styles.cell}>
        <ListTooltip list={vendorNames} />
      </TableCell>

      <TableCell sx={styles.cell}>
        <ListTooltip list={displayedCostArticles} />
      </TableCell>

      <TableCell sx={styles.cell} align="center">
        <Typography fontFamily="mono">{currencyCodeGetter(item.currency_id)}</Typography>
      </TableCell>

      <TableCell sx={styles.cell} align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.total_price)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default RequestsListRow;
