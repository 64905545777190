import { ApproveStatusEnum, type DocumentBaseV2Schema, RequestDetailOutputV2Schema, StatusV2Enum } from '@/generated';
import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { CurrencyCircleDollar, ImagesSquare, Invoice } from '@phosphor-icons/react';
import { DATE_FORMAT } from 'app/consts/app';
import { AmountFormatter, CurrencyCodeGetterById } from 'app/types/common';
import { formatTime } from 'app/utils/dates';
import IconButtonWithTooltip from 'components/IconButtonWithTooltip';
import { FormattedMessage } from 'components/Intl';
import isNil from 'lodash-es/isNil';
import { openBackofficeModal } from 'pages/backoffice/Modals/actions';
import { ImageCarouselModalData, MasterDocumentModalData } from 'pages/backoffice/Modals/command';
import { updatePaidStatus } from 'pages/backoffice/Requests/ViewRequestPanel/actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { DocumentApproveButtons } from '..';
import useStyles from './styles';

interface IProps {
  request?: RequestDetailOutputV2Schema;
  document: DocumentBaseV2Schema;
  buttonsDisabled: boolean;
  amountFormatter: AmountFormatter;
  getCurrencyCode: CurrencyCodeGetterById;
}

const ViewRequestPanelContentDocumentsItemDocument = (props: IProps) => {
  const { request, document, buttonsDisabled, getCurrencyCode, amountFormatter } = props;

  const dispatch = useDispatch();
  const styles = useStyles(document?.is_paid ?? false);

  const handleOpenFilePreview = () => {
    const fileLinks = document?.files?.map((file) => file.link) ?? [];

    dispatch(openBackofficeModal(new ImageCarouselModalData(fileLinks, document.id, document.calculated_status)));
  };

  const handleOpenMasterDocument = () => {
    dispatch(openBackofficeModal(new MasterDocumentModalData({ documentId: document.id })));
  };

  const handlePaidChange = () => {
    if (request?.id && !isNil(document.is_paid)) {
      dispatch(
        updatePaidStatus({
          requestId: request.id,
          documentId: document.id,
          status: !document.is_paid,
        })
      );
    }
  };

  const name = document.vendor_name ?? <FormattedMessage id="label.unknown" />;
  const uploadedAt = document.created_at ? formatTime(document.created_at, { dateFormat: DATE_FORMAT }) : '';
  const uploadedBy =
    [document.created_by_first_name, document.created_by_last_name].filter(Boolean).join(' ') ||
    document.created_by_email;
  const showPaidButton =
    document.calculated_status !== ApproveStatusEnum.DECLINED && request?.status !== StatusV2Enum.DECLINED;

  return (
    <Grid item container sx={styles.container}>
      <Grid item container width="auto" wrap="nowrap">
        <SvgIcon fontSize="large">
          <Invoice />
        </SvgIcon>
      </Grid>

      <Grid item container direction="column" gap={4} marginLeft={3} wrap="nowrap">
        <Grid item container direction="column" gap={2} wrap="nowrap">
          <Typography fontWeight="semiBold">{name}</Typography>

          <Typography color={COLOURS.Coconut.Solid[600]}>
            <FormattedMessage
              id={uploadedBy ? 'request.uploaded_at_and_by' : 'request.uploaded_at'}
              values={{ uploadedAt, uploadedBy }}
            />
          </Typography>
        </Grid>

        <Grid item container gap={4} wrap="nowrap">
          <DocumentApproveButtons
            requestId={request?.id}
            requestStatus={request?.status}
            documentId={document.id}
            documentApproveStatus={document.document_approve_status}
            disabled={buttonsDisabled}
          />

          {showPaidButton && (
            <Button
              variant="outlined"
              color="blue"
              sx={styles.paidButton}
              label={<FormattedMessage id="label.paid" />}
              startIcon={<CurrencyCircleDollar />}
              disabled={buttonsDisabled}
              onClick={handlePaidChange}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        item
        container
        marginLeft="auto"
        direction="column"
        justifyContent="space-between"
        width="auto"
        wrap="nowrap"
      >
        <Grid item>
          <Typography fontWeight="semiBold" noWrap>
            {getCurrencyCode(document.total_price_currency_id)} {amountFormatter(document.total_price)}
          </Typography>
        </Grid>

        <Grid item container justifyContent="flex-end" gap={1} width="auto" wrap="nowrap">
          <IconButtonWithTooltip
            onClick={handleOpenFilePreview}
            icon={<ImagesSquare />}
            disabled={buttonsDisabled}
            tooltipLabel="label.open_source_file"
            ariaLabel="aria.label.open_file_preview"
            dataTestId="requests-view-panel-documents-document-item-open-file-preview"
          />

          <IconButtonWithTooltip
            onClick={handleOpenMasterDocument}
            disabled={buttonsDisabled}
            tooltipLabel="label.open_document"
            ariaLabel="aria.label.open_item"
            dataTestId="requests-view-panel-documents-document-item-open-master-document"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewRequestPanelContentDocumentsItemDocument;
