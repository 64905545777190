import Button from '@core-ui/button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FilePlus } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import LoadFileDropzone from 'components/LoadFileDropzone';
import { useAppSelector } from 'hooks/useAppSelector';
import { addCreateEditRequestFiles } from 'pages/backoffice/Requests/CreateEditRequestPanel/actions';
import { AttachedDocumentsList } from 'pages/backoffice/Requests/CreateEditRequestPanel/components/CreateEditRequestPanelForm/components';
import React from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './styles';
import selector from './selector';

const AttachedDocuments = () => {
  const { dropzoneDisabled } = useAppSelector(selector);
  const dispatch = useDispatch();
  const styles = useStyles();

  const handleDropFiles = (newFiles: File[]) => {
    dispatch(addCreateEditRequestFiles(newFiles));
  };

  return (
    <LoadFileDropzone
      disabled={dropzoneDisabled}
      reducerName="test_reducer"
      handleOnDrop={handleDropFiles}
      render={(props, { getRootProps, getInputProps }) => (
        <Grid container paddingBottom={4} {...getRootProps()}>
          <input {...getInputProps()} />

          <Grid item container sx={styles.contentContainer}>
            <Grid item container wrap="nowrap" justifyContent="space-between" alignItems="center">
              <Typography fontSize="smaller" color="text.secondary">
                <FormattedMessage id="label.documents" />
              </Typography>

              <Button
                variant="text"
                color="blue"
                compact
                disabled={dropzoneDisabled}
                startIcon={<FilePlus />}
                label={<FormattedMessage id="label.add_documents" />}
              />
            </Grid>

            <AttachedDocumentsList />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default AttachedDocuments;
