import { RequestSortingColumnsV2Enum } from '@/generated';
import Tooltip from '@mui/material/Tooltip';
import { FormattedMessage } from 'components/Intl';
import { ITableColumn } from 'pages/backoffice/types';
import React from 'react';

export const getHeaders = (costArticleMappingEnabled: boolean): ITableColumn[] => {
  let costArticlesColumnHeader: ITableColumn = {
    key: 'cost_articles',
    title: <FormattedMessage id="table.header.cost_articles" />,
    width: 130,
  };

  if (costArticleMappingEnabled) {
    costArticlesColumnHeader = {
      key: 'erp_categories',
      title: <FormattedMessage id="table.header.erp_categories" />,
      width: 130,
    };
  }

  return [
    {
      key: 'name',
      title: <FormattedMessage id="table.header.request_name" />,
      orderBy: RequestSortingColumnsV2Enum.NAME,
      width: 320,
      dataTestId: 'requests-list-table-column-head-request-name',
    },
    {
      key: 'status',
      title: <FormattedMessage id="table.header.status" />,
      width: 180,
    },
    {
      key: 'created_at',
      title: <FormattedMessage id="table.header.created_at" />,
      orderBy: RequestSortingColumnsV2Enum.CREATED_AT,
      width: 130,
      dataTestId: 'requests-list-table-column-head-created-at',
    },
    {
      key: 'employee',
      title: <FormattedMessage id="table.header.creator" />,
      orderBy: RequestSortingColumnsV2Enum.CREATED_BY_EMAIL,
      width: 130,
      dataTestId: 'requests-list-table-column-head-creator',
    },
    {
      key: 'vendor',
      title: <FormattedMessage id="table.header.vendor" />,
      width: 130,
    },

    costArticlesColumnHeader,

    {
      key: 'currency',
      title: (
        <Tooltip title={<FormattedMessage id="table.header.currency" />} placement="top" arrow>
          <div>
            <FormattedMessage id="table.header.currency_short" />
          </div>
        </Tooltip>
      ),
      align: 'center',
      width: 54,
    },
    {
      key: 'total_price',
      title: <FormattedMessage id="table.header.documents_total" />,
      // TODO: добавить сортировку по total_price когда она появится на бэке
      // orderBy: RequestSortingColumnsV2Enum.TOTAL_PRICE,
      width: 130,
    },
  ];
};
